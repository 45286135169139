import React from 'react'
import { graphql } from 'gatsby'
import { MyLayout, NameLink, StyledPostLink, StyledTextArea } from '../components/common'
import styled from 'styled-components' 
import Helmet from 'react-helmet'

const StyledButtonFooter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Template = ({ data, pageContext }) => {
    const { next, prev } = pageContext
    const { markdownRemark } = data
    const html = markdownRemark.html
    const title = markdownRemark.frontmatter.title
    const excerpt = markdownRemark.frontmatter.excerpt
    return (
        <MyLayout>
        <Helmet htmlAttributes={{"lang": "en"}}>
            <title>{title}</title>
            <meta name="description" content={excerpt} />
        </Helmet>
            <NameLink />
            <StyledTextArea>
            <h2>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            </StyledTextArea>
            <StyledButtonFooter>
                {prev ?
                    <StyledPostLink to={prev.frontmatter.path}>Previous</StyledPostLink> : <span />}
                {next &&
                    <StyledPostLink to={next.frontmatter.path}>Next</StyledPostLink>}

            </StyledButtonFooter>
        </MyLayout>
    )
}

export const query = graphql`
    query($pathSlug: String!){
        markdownRemark(frontmatter: {path: {eq: $pathSlug}}) {
            html
            frontmatter {
                title
                excerpt
            }
        }
    }
`

export default Template
